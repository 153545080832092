<template>
	<div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3 h-100">
      <div class="col mx-auto align-self-center">
         <div class="card">
            <div class="card-body">
               <div class="border p-4 rounded">
                  <div class="text-center">
                     <div id="login" class="mb-4 text-center">
                        <img src="@/assets/admin/images/logo-icon.png" class="logo-icon" alt="logo icon">
                        <img src="@/assets/admin/images/logo-text.png" class="logo-img-text" alt="logo text">
                     </div>
                     <h6 class="mb-3 font-18">Faça login para continuar!</h6>
                  </div>
                  <div class="form-body">
                     <form class="row g-3">
                        <div class="col-12">
                           <label class="mb-1"><i class="fas fa-user me-2 font-12"></i>Usuário</label>
                           <input type="text" class="form-control font-14" placeholder="Digite seu usuário" v-model="dados.usuario" @keyup.enter="verificarLogin">
                        </div>
                        <div class="col-12">
                           <label class="mb-1"><i class="fas fa-lock me-2 font-12"></i>Senha</label>
                           <input type="password" class="form-control font-14" placeholder="Digite sua senha" v-model="dados.senha" @keyup.enter="verificarLogin">
                        </div>
                        <div class="col-12 text-center">Esqueceu a senha? Contate o suporte!</div>
                        <div class="col-12">
                           <div class="d-grid">
                              <button type="button" class="btn btn-primary font-14" @click="verificarLogin">
                                 <i class="far fa-sign-in-alt me-1"></i> Entrar
                              </button>
                           </div>
                        </div>
                        <div class="col-12 text-center"><span class="cursor-pointer" @click="setRouteCliente">Voltar</span></div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'

export default {
	name: 'AdmLogin',
   data: function() {
		return {
			dados: {'usuario': '', 'senha': ''}
		}
	},
   methods: {
      setRouteCliente : function () {
         window.location.reload()
      },
		verificarLogin : function () {
         if (this.dados.usuario.length > 0 && this.dados.senha.length > 0) {
            this.$store.dispatch('loginAdmin', {'usuario': this.dados.usuario, 'senha': this.dados.senha});
         }
		}
	},
   created() {
      this.$store.dispatch('loginAdmin')

      if (localStorage.theme != null) {
         $("html").addClass(localStorage.theme)
      }
   }
}

</script>